<template>
  <v-container>
    <v-row class="my-3">
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(coupon, index) in coupons"
        :key="'coupon-' + index"
      >
        <UsedCouponCard :coupon="coupon" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CouponsService from "@/service/couponsService";
import UsedCouponCard from "@/components/coupon/UsedCouponCard.vue";
import get from "lodash/get";
export default {
  name: "DiscountHistory",
  components: { UsedCouponCard },
  data() {
    return {
      coupons: []
    };
  },
  methods: {
    async reload() {
      await CouponsService.getRedeemedDiscounts().then(data => {
        if (data.values) {
          this.coupons.push(
            ...data.values.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.$dayjs(item.dateRedemption).format("DD/MM/YYYY"),
              amount: item.product.name,
              img: item.product.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedTotalCoupons().then(data => {
        if (data.giftCertificates) {
          this.coupons.push(
            ...data.giftCertificates.map(item => ({
              name: item.name,
              date: null,
              amount: item.userGiftCertificates[0].amount,
              amountType: this.discountType(
                get(item, "giftAdjustmentType.descr")
              ),
              img: item.categoryProposal.img,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedDiscountsByType("cashback").then(data => {
        if (data.values && data.values.length > 0) {
          this.coupons.push(
            ...data.values?.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.$dayjs(item.startDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ), //??
              amount: item.name,
              img: item.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedDiscountsByType("welcome").then(data => {
        if (data.values && data.values.length > 0) {
          this.coupons.push(
            ...data.values?.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.$dayjs(item.startRetreat, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              ), //??
              amount: item.product.name,
              img: item.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedDonations().then(data => {
        if (data.values) {
          this.coupons.push(
            ...data.values.map(item => ({
              name: this.$t("coupons.discounts.donatePoints"),
              description: item.product.name,
              date: this.$dayjs(item.dateRedemption).format("DD/MM/YYYY"),
              amount: "",
              img: item.product.mediaURL,
              type: "donation"
            }))
          );
        }
      });
    },
    discountType(type) {
      //let type = get(this.coupon, "giftAdjustmentType.descr");
      switch (type) {
        case "PercentOff":
          return "%";
        case "FixedAmounOff":
          return "€";
        default:
          return "";
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
