<template>
  <v-card elevation="2" class="used-coupon-card rounded-lg d-flex pa-1">
    <div class="d-flex align-center">
      <img
        :src="coupon.img ? coupon.img : ''"
        alt=""
        class="coupon-img rounded-lg"
      />
    </div>
    <div class="d-flex flex-column flex-grow-1 pl-3">
      <div class="coupon-info pr-2" v-if="coupon.type == 'discount'">
        <div class="coupon-name pr-2">
          {{ coupon.name }}
        </div>
        <div class="primary--text text-h3 font-weight-semibold flex-shrink-0">
          {{ coupon.amount }}{{ coupon.amountType }}
        </div>
      </div>
      <div class="donation-info" v-if="coupon.type == 'donation'">
        <div class="my-2">
          {{ coupon.name }}
        </div>
        <div class="donation-description pr-2">
          {{ coupon.description }}
        </div>
      </div>
      <div class="my-2">
        <span v-if="coupon.date"
          >{{
            coupon.type == "donation"
              ? $t("coupons.discounts.donationUsed")
              : $t("coupons.discounts.used")
          }}
          {{ coupon.date }}</span
        ><span v-else>{{ $t("coupons.discounts.alreadyUsed") }}</span>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.used-coupon-card {
  height: 110px;
  .coupon-img {
    width: 100px;
  }
  .coupon-info {
    border-bottom: 2px dashed $primary;
    flex-grow: 1;
    display: flex;
    align-items: center;
    .coupon-name {
      flex-grow: 1;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .donation-info {
    border-bottom: 2px dashed $primary;
    .donation-description {
      font-weight: 600;
      margin: 5px 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<script>
export default {
  name: "UsedCouponCard",
  props: {
    coupon: { type: Object, required: true }
  }
};
</script>
